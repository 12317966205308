<!--能源模块--设备卡片组件-->
<template>
  <div id="siteKanBanSetting" >
    <a-card :title="item.ruleTitle" :class="(item.ruleModual==13?'card-13':'card')" v-for="(item,index) in list" :key="index">
      <div style="width:100%;height:100%;">
        <!-- 能源使用量 -->
        <a-descriptions v-if="item.ruleModual==1" title="" bordered size="small">
          <a-descriptions-item :label="$t('energy.enedata286')" :span="3">
            <!-- <a-select style="width: 200px">
              :label="$t('energy.enedata286')"
            </a-select> -->

            <a-tree-select
                v-model="item.points"
                multiple
                style="width:240px;max-height:100px;overflow: auto;font-size:12px;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="item.dataList"
                :replace-fields="{children:'children', title:'name', key:'key', value: 'key'}"
                :placeholder="placeholder"
                allow-clear
                tree-default-expand-all>
            </a-tree-select>
          </a-descriptions-item>
          <!-- label="总功率" -->
          <a-descriptions-item v-if="item.kind==1" :label="$t('Kanban.a12')" :span="3">
            <a-tree-select
                v-model="item.powers"
                multiple
                style="width:240px;max-height:70px;overflow: auto;font-size:12px;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="item.powerList"
                :replace-fields="{children:'children', title:'name', key:'key', value: 'key'}"
                :placeholder="placeholder"
                allow-clear
                tree-default-expand-all>
            </a-tree-select>
          </a-descriptions-item>
          <!-- :label="$t('Kanban.a13')" -->
          <a-descriptions-item :label="$t('Kanban.a13')" :span="3">
            <a-switch v-model="item.display"  />
          </a-descriptions-item>
        </a-descriptions>
        <!-- 清洁能源 -->
        <a-descriptions v-if="item.ruleModual==2" title="" bordered size="small">
          <a-descriptions-item :label="$t('Kanban.a57')" :span="3">
              <a-tree-select
                v-model="item.points"
                multiple
                style="width:240px;max-height:100px;overflow: auto;font-size:12px;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="item.dataList"
                :replace-fields="{children:'children', title:'name', key:'key', value: 'key'}"
                :placeholder="placeholder"
                allow-clear
                tree-default-expand-all>
            </a-tree-select>
          </a-descriptions-item>

          <a-descriptions-item :label="$t('Kanban.a13')" :span="3">
            <a-switch v-model="item.display"  />
          </a-descriptions-item>
        </a-descriptions>
        <!-- 能源流向 -->
        <a-descriptions v-if="item.ruleModual==4" title="" bordered size="small">
          <a-descriptions-item :label="$t('Kanban.a14')" :span="3">
            <a-radio-group v-model="item.kind" button-style="solid" >
              <a-radio-button  v-for="(ritem, index) in item.dataList" :key="index" :value="ritem.no"> {{ ritem.name }}</a-radio-button>
            </a-radio-group>
            
          </a-descriptions-item>
          <!-- label="显示层级" -->
          <a-descriptions-item :label="$t('Kanban.a15')" :span="3">
            <a-tree-select
                v-model="item.level"
                multiple
                style="width:240px;max-height:100px;overflow: auto;font-size:12px;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="item.types"
                :replace-fields="{children:'children', title:'text', key:'no', value: 'no'}"
                :placeholder="placeholder"
                allow-clear
                tree-default-expand-all>
            </a-tree-select>
          </a-descriptions-item>
        </a-descriptions>
        <!-- 警报统计站点 -->
        <a-descriptions v-if="item.ruleModual==5" title="" bordered size="small">
          <!-- label="关联站点" -->
          <a-descriptions-item :label="$t('Kanban.a16')" :span="3">
            <a-tree-select
                v-model="item.sites"
                multiple
                style="width:240px;max-height:100px;overflow: auto;font-size:12px;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="item.dataList"
                :replace-fields="{children:'children', title:'name', key:'key', value: 'key'}"
                :placeholder="placeholder"
                allow-clear
                tree-default-expand-all>
            </a-tree-select>
          </a-descriptions-item>
        </a-descriptions>
        <!-- 其他参数 -->
        <a-descriptions v-if="item.ruleModual==9" title="" bordered size="small">
          <!-- label="站点地址" -->
          <a-descriptions-item :label="$t('Kanban.a17')" :span="3">
            <a-cascader
              style="width: 240px;"
              v-model="item.addrCodes"
              :field-names="{ label: 'name', value: 'code', children: 'children' }"
              :options="item.divisions"
              :placeholder="placeholder"
              @change="(value, selectedOptions) => addrChange(value, selectedOptions,item)"
            />
            <br/>
            <a-input v-model="item.address" style="width: 240px;margin-top:3px;"></a-input>
          </a-descriptions-item>
          <!-- label="数据比较" -->
          <a-descriptions-item :label="$t('Kanban.a18')" :span="3">
            <a-radio-group v-model="item.rateType" button-style="solid" >
              <a-radio-button  v-for="(ritem, index) in item.types" :key="index" :value="ritem.no"> {{ ritem.text }}</a-radio-button>
            </a-radio-group>
          </a-descriptions-item>

          <a-descriptions-item :label="$t('Kanban.a14')" :span="3">
            <a-radio-group v-model="item.reportType" button-style="solid" >
              <a-radio-button  v-for="(ritem, index) in item.type2s" :key="index" :value="ritem.no"> {{ ritem.text }}</a-radio-button>
            </a-radio-group>
          </a-descriptions-item>
          <!-- label="KPI规则" -->
          <a-descriptions-item v-if="item.ruleType==3" :label="$t('Kanban.a36')" :span="3"> 
            <a-select v-model="item.yearRuleId" style="width: 160px" :placeholder="placeholder">
                <a-select-option :title="ritem.name"
                    v-for="(ritem, index) in item.yearRuleData"
                    :key="index"
                    :value="ritem.no" 
                    >
                    {{ ritem.name }}
                </a-select-option>
            </a-select>
          </a-descriptions-item>

        </a-descriptions>
        <!-- KPI 规则 -->
        <a-descriptions v-if="item.ruleModual==11 && item.ruleType==2" title="" bordered size="small" style="height: 260px;overflow: auto;">
            <a-descriptions-item :label="site.name" :span="3"  v-for="(site,idx) in item.yearRuleData" :key="idx" >
              <a-select v-model="site.value" style="width: 160px;" :placeholder="placeholder">
                <a-select-option :title="ritem.name"
                    v-for="(ritem, index) in site.children"
                    :key="index"
                    :value="ritem.no" 
                    >
                    {{ ritem.name }}
                </a-select-option>
              </a-select>
            </a-descriptions-item>
        </a-descriptions>
        
        <!-- 分段规则 -->
        <div v-if="item.ruleModual==13" class="sec-box" >
            <div v-if="item.ruleType!=3" style="width:100%;padding:2px 0;">
              <a-descriptions title="" bordered size="small">
                  <!-- label="分段规则使用" -->
                  <a-descriptions-item :label="$t('Kanban.a19')" :span="2">
                      <!-- 使用站点分段规则  统一使用建筑分段规则-->
                      <a-switch v-model="item.siteSection" :checked-children="$t('Kanban.a20')" :un-checked-children="$t('Kanban.a21')" />
                      
                  </a-descriptions-item>
                  <!-- label="注意" -->
                  <a-descriptions-item :label="$t('Kanban.a35')" :span="1">
                    <!-- <span>当选择“使用站点分段规则”时，如果对应站点未设置分段规则，则使用建筑的分段规则。当数据类型选择“日”时；统一使用建筑分段规则。</span> -->
                    <span>{{$t('Kanban.a23')}}</span> 
                  </a-descriptions-item>
              </a-descriptions>
            </div>
            <table>
              <tr class="sec-box-head-row">
                  <!-- 月份 -->
                  <th class="sec-box-head-row-th sec-box-row-title" >{{$t('energy.enedata442')}}</th>
                  <th style="padding:4px 0;"      v-for="i in 12" :key="i">{{item.months[i-1]}}</th>
                  <th class="sec-box-head-row-th sec-box-row-title"></th>
              </tr>
               <tr class="sec-box-head-row">
                  <!-- 小时 -->
                  <th class="sec-box-head-row-th sec-box-row-title" >{{$t('energy.enedata157')}}</th>
                  <th style="padding:4px 0;"      v-for="i in 12" :key="i">
                      <a-select  class="sec-box-row-len" :placeholder="placeholder" :title="$t('Kanban.a25')"
                        @change="(value, option) => batchEnergySectionChange(value, option,item,i-1,'col')"  >
                      <a-select-option :title="ritem.name"  v-for="(ritem, index) in item.types" :key="index"  :value="ritem.no"  >
                          {{ ritem.text }}
                      </a-select-option>
                    </a-select>
                  </th>
                  <!-- 快捷选择 -->
                  <th class="sec-box-head-row-th sec-box-row-title"> <span>{{$t('Kanban.a27')}}</span> </th>
              </tr>
              <tr :class="('sec-box-row-'+(i%2))"  v-for="(shour,i) in item.hours" :key="i">
                <th class="sec-box-row-title">{{shour}}</th>
                <td v-for="j in 12" :key="j"  >
                  <a-select v-model="item.energySectionRules[j-1][i].value" class="sec-box-row-len" :placeholder="placeholder" 
                        @change="(value, option) => energySectionChange(value, option,item,j-1,i)"
                      
                      >
                      <a-select-option :title="ritem.name"
                          v-for="(ritem, index) in item.types"
                          :key="index"
                          :value="ritem.no" 
                          >
                          {{ ritem.text }}
                      </a-select-option>
                    </a-select>
                </td>
                <th>
                    <a-select  class="sec-box-row-len" :placeholder="placeholder" :title="$t('Kanban.a26')"
                        @change="(value, option) => batchEnergySectionChange(value, option,item,i,'row')"  >
                      <a-select-option :title="ritem.name"  v-for="(ritem, index) in item.types" :key="index"  :value="ritem.no"  >
                          {{ ritem.text }}
                      </a-select-option>
                    </a-select>
                  </th>
              </tr>
            </table>
          
        </div>
      </div>
    </a-card>
  
  </div>
</template>

<script>

export default {
  name: "siteKanBanSetting",
  props: {
    list: {
      type: Array,
      default:[],
    },
  },
  data() {
    return {
      loading: false, 
      placeholder:this.$t('energy.placeSelect'),//'请选择',
      value:[],
      firstRow:{paddingTop:'5px'},
    };
  },
  mounted(){

  },
  methods: {
    batchEnergySectionChange(value, option,item,i,type){
        console.log("batchEnergySectionChange:",value);
        console.log("batchEnergySectionChange:",option);
        console.log("batchEnergySectionChange:(i)>>",i);
        if(type=='row'){
          for (let k = 0; k < 12; k++) {
            const element = item.energySectionRules[k][i];
            element.value = value;
          }
        }else{
           for (let k = 0; k < 24; k++) {
            const element = item.energySectionRules[i][k];
            element.value = value;
          }
        }
    },
    energySectionChange(value, option,item,i,j){
        console.log("energySectionChange:",value);
        console.log("energySectionChange:",option);
        console.log("energySectionChange:(i,j)>>",i,j);
        // item.energySectionRules[i][j].value = value;
    },
    addrChange(value, selectedOptions,record){
        console.log(value);
        console.log(record);
        let names = [];
        selectedOptions.forEach(element => {
          names.push(element.name);
        });
        record.addrNames = names;
        console.log(names);
    },
    
  },
  computed: {
   
  },
};
</script>

<style scoped>
#siteKanBanSetting {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    font-family: "Microsoft YaHei";
}
.card{
  /* width: 30rem;
  height: 20rem;
  padding: 1rem;
  padding: 5px;
  margin: 1rem; */
  width: 480px;
  height: 320px;
  margin: 5px;
}
.card-13{
  /* width: 30rem;
  height: 20rem;
  padding: 1rem;
  padding: 5px;
  margin: 1rem; */
  width: calc(100% - 0.1rem);
  margin: 5px;
  margin-bottom: 0.3rem;
 
}
.sec-box{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.sec-box-head-row{
  border-bottom:1px solid #f5f5f5;
  background-color:#e8e8e8;
}
.sec-box-head-row-th{
  width: 80px;
  padding:4px 0;
}
.sec-box-row-0{
  background-color: aliceblue;
}
.sec-box-row-1{
  background-color:#ffffff;
}
.sec-box-row-title{
  width: 80px;
  white-space:nowrap;
  padding-top:4px;
  background-color:#e8e8e8;
  padding-left:5px;
}
.sec-box table{
  width: 100%;
  margin-bottom:2px ;
}
.sec-box table tr.sec-box-row-0:hover{
  background-color:#7682ce;
}
.sec-box table tr.sec-box-row-1:hover{
  background-color:#7682ce;
}
.sec-box-row-len{
  width: 80px;
}


/* .card-13 table /deep/.ant-select {
  font-size: 12px;
}

.card-13 table /deep/.ant-select-dropdown-menu-item {
  font-size: 12px;
} */
div /deep/.ant-card-head-title{
  padding:8px 0;
}
div /deep/.ant-card-body {
    padding: 4px 8px ;
    zoom: 1;
    height: calc(100% - 50px);
}
div /deep/.ant-switch-checked {
    background-color: #7682ce;
}
div /deep/.ant-card-head {
    background: #e8e8e8;
}

 div /deep/.ant-select-tree-node-selected{
     background-color: #7682ce;
 }
 div /deep/.ant-descriptions-item-label{
  white-space: nowrap;
} 
</style>