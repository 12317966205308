<template>
    <div id="kanBanSetting"  v-loading="loading"  :element-loading-text="$t('alarm.a0')">
        <div class="header">
             
            <div class="header-content">
                <!-- 看板设置 -->
                <div class="header-title "> {{$t('Kanban.a10')}}</div>
                <div>
                    <a-tree-select
                        v-model="value"
                        show-search
                        style="width: 400px"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="energySiteList"
                        :replace-fields="replaceFields"
                        placeholder="Please select"
                        allow-clear
                        tree-default-expand-all
                        @change="search"
                        >
                    </a-tree-select>
                    <!-- 查询 -->
                    <a-button type="primary" style="margin-left:5px;" icon="search" @click="search">{{$t('energy.search')}}</a-button>
                    <!-- 返回 -->
                    <a-button type="primary" style="margin-left:5px;" icon="rollback" @click="goBack">{{$t('Kanban.a32')}}</a-button>
                </div>
            </div>
        </div>
        
        <div class="main">
            <site-kanban-setting   :list="energyKanBanSetting" v-if="(level=='site' || level=='sitegr') && energyKanBanSetting.length>0" />
            <client-kanban-setting :list="energyKanBanSetting" v-else-if="level=='client' && energyKanBanSetting.length>0" />
            <a-empty  v-else />
        </div>
        <div class="floor">
            <!-- 设置 -->
            <a-button type="primary" icon="save" :disabled="energyKanBanSetting.length==0" @click="save" style="margin-left:10px;">{{$t('energy.ok')}}</a-button>
        </div>
    </div>
</template>
<script>
import { getEnergySiteList,getEnergyKanBanSetting,saveEnergyKanBanSetting } from "../../api/energy";
import siteKanbanSetting from './components/siteKanBanSetting'                  // 建筑/站点看板
import clientKanBanSetting from './components/clientKanBanSetting'              // 集团/客户看板
export default {
    data() {
        return {
            loading: false,
            value:'',
            level:'',
            id:'',
            energySiteList:[],
            energyKanBanSetting:[],
            replaceFields:{children:'children', title:'name', key:'key', value: 'key' },
            userId:0,
        };
    },
    activated(){
        
    },
    mounted(){
        this.userId = JSON.parse(sessionStorage.getItem('user')).id;
            
        this.value =  localStorage.getItem('kanbanValue'+this.userId);
        this.getEnergySiteList();
    },
  methods: {
      goBack(){
          this.$router.go(-1);
      },
      search(){
        this.id     = '';
        this.level  = ''; 
        if(!this.value){
            // this.$message.error('请选择客户/建筑/站点信息！');
            this.$message.error(this.$t('Kanban.a50'));
            return;
        }
        localStorage.setItem('kanbanValue'+this.userId,this.value);
        this.$route.query.id = this.value;
        let vals    = this.value.split('@@');
        this.id     = vals[0];
        this.level  = vals[1]; 
        let param   =   {
            level:this.level,
            id:this.id
        };
        this.loading = true;
        this.energyKanBanSetting = [];
        getEnergyKanBanSetting(param)
        .then((res) => {
            if(res.errorCode ==='00' && res.data.kanBanRuleList){
                this.energyKanBanSetting = res.data.kanBanRuleList;
            }else{
                this.$message.error(res.msg);
            }
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },
      getEnergySiteList(){
            this.loading = true;
            getEnergySiteList()
            .then((res) => {
                this.energySiteList = res.data;
                this.loading = false;
                this.search();
            })
            .catch((err) => {
                this.loading = false;
            });
      },
      save(){
        let kanBanRuleList = new Array();
        for (let i = 0; i < this.energyKanBanSetting.length; i++) {
            let rule = JSON.stringify(this.energyKanBanSetting[i]);
            kanBanRuleList.push(JSON.parse(rule));
        }
        
        let emsg = '';
        for (let i = 0; i < kanBanRuleList.length; i++) {
            let rule = kanBanRuleList[i];
            let modual =rule.ruleModual; 
            rule.ruleTitle = '';
            switch (modual) {
                case 1://能源使用模块
                    rule.dataList=null;
                    rule.powerList=null;
                    break;
                case 2://清洁能源模块
                    rule.dataList=null;
                    break;
                case 3://节能减排
                    break;
                case 4://能源流向
                    rule.types=null;
                    rule.dataList=null;
                    break;
                case 5://警报设置
                    rule.dataList=null;
                    break;
                case 6://天气地址
                    break;
                case 7://数据比较类型
                    break;
                case 8://报表类型
                    break;
                case 9://其他参数 6/7/8 三合一
                    emsg = this.check(rule.address,false,1,100);
                    if(emsg==='1'){
                        emsg = this.$t('Kanban.a51');//'请输入详细地址。';
                    }else if(emsg==='2'){
                        emsg = this.$t('Kanban.a52');// '详细地址长度必须在1~100之间。';
                    }else if(emsg==='3'){
                        emsg = this.$t('Kanban.a53');// '详细地址不可以包含特殊字符。';
                    }else {
                        emsg = '';
                    }
                    rule.types=null;
                    rule.type2s=null;
                    rule.divisions=null;
                    break;
                case 10://分公司
                    rule.dataList=null;
                    break;
                case 13://分公司
                    rule.hours=null;
                    rule.months=null;
                    break;
                default:
                    break;
            }
        }
        if(emsg!=''){
            this.$message.error(emsg);
            return;
        }
        let param = {
            level:this.level,
            id:this.id,
            kanBanRuleList:kanBanRuleList,
        };

        this.$confirm({
            title:this.$t("energy.enedata184"),
            centered: true,
            onOk: () => {
                this.loading = true;
                saveEnergyKanBanSetting(param)
                .then((res) => {
                    console.log('SaveEnergyKanBanSetting result',res);
                    if(res.errorCode ==='00' || res.errorCode ==='03'){
                    this.$message.success(res.msg);
                    }else{
                    this.$message.error(res.msg);
                    }
                    this.loading = false;
                    })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            }
        });
            
      },
      check(value,required, min,max){
            if(required && (value===undefined || value==='')){
                return "1";
            }
            let len = value.length;
            if(len>0 && (len<min || len>max)){
                return "2";
            }
            if(len>0  && this.checkString(value)){
                return "3";
            }
            return "";
      },
      checkString(value){
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
        return regEn.test(value) ;
	 },
  }, 
  components: {
    'site-kanban-setting':siteKanbanSetting,
    'client-kanban-setting':clientKanBanSetting
  }
}
</script>
<style scoped>
#kanBanSetting{
    width: 100%;
    height: 100%;
    background-color: #f2f3f8;
    position: relative;
    font-family: "Microsoft YaHei";
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    height: 0.50rem;
    padding: 10px;
    border-left: 1px solid rgba(12, 12, 12, 0.1);
}
.header-content{
    width: 100%;display: flex; justify-content: space-between;
    border-bottom: 1px solid #cccccc;
}

.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background-color: #7682ce;
  margin-right: 20px;
}
.header-name{ display: flex; justify-content: space-between; }
.main{
    width: 100%;
    height: calc(100% - 0.52rem);
    padding: 0.1rem;    
    background-color: #ffffff;
    border-left: 1px solid rgba(12, 12, 12, 0.1);
}
.floor{
    position:absolute;
    bottom:0;
    width: 100%;
    text-align: right;
    padding: 10px 30px;
    z-index: 9999;
}

</style>