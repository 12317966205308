<!--能源模块--设备卡片组件-->
<template>
  <div id="clientKanBanSetting" >
    <a-card :title="item.ruleTitle" :class="(item.ruleModual>9?'card-'+item.ruleModual:'card')" v-for="(item,index) in list" :key="index">
      <!--  添加信息-->
      <a-button v-if="item.ruleModual==10" slot="extra"  type="primary" shape="circle" icon="plus"  @click="addBranch(item)" :title="$t('Kanban.a39')" /> 

      <div style="width:100%;height:100%;">
        <!-- 其他参数 -->
        <a-descriptions v-if="item.ruleModual==9" title="" bordered size="small">
          <!-- label="集团地址"   -->
          <a-descriptions-item :label="$t('Kanban.a56')" :span="3">
            <a-cascader
              style="width: 240px;"
              v-model="item.addrCodes"
              :field-names="{ label: 'name', value: 'code', children: 'children' }"
              :options="item.divisions"
              :placeholder="placeholder"
              @change="(value, selectedOptions) => addrChange(value, selectedOptions,item)"
            /> 
            <a-input v-model="item.address" :class="item.clzz" style="width: 240px;margin-left:5px;"></a-input>
          </a-descriptions-item>
          <!-- label="数据比较" -->
          <a-descriptions-item :label="$t('Kanban.a18')" :span="3">
            <a-radio-group v-model="item.rateType" button-style="solid" >
              <a-radio-button  v-for="(ritem, index) in item.types" :key="index" :value="ritem.no"> {{ ritem.text }}</a-radio-button>
            </a-radio-group>
          </a-descriptions-item>

          <a-descriptions-item :label="$t('Kanban.a14')" :span="3">
            <a-radio-group v-model="item.reportType" button-style="solid" >
              <a-radio-button  v-for="(ritem, index) in item.type2s" :key="index" :value="ritem.no"> {{ ritem.text }}</a-radio-button>
            </a-radio-group>
          </a-descriptions-item>
        </a-descriptions>
        
        <!-- 分段规则 -->
        <div v-if="item.ruleModual==13"  class="sec-box" >
            <div v-if="item.ruleType!=3" style="width:100%;padding:2px 0;">
              <a-descriptions title="" bordered size="small">
                  <!-- label="分段规则使用" -->
                  <a-descriptions-item :label="$t('Kanban.a19')" :span="2">
                      <!-- 使用站点分段规则  统一使用建筑分段规则-->
                      <a-switch v-model="item.siteSection" :checked-children="$t('Kanban.a20')" :un-checked-children="$t('Kanban.a22')" />
                      
                  </a-descriptions-item>
                  <!-- label="注意" -->
                  <a-descriptions-item :label="$t('Kanban.a35')" :span="1">
                    <!-- <span>当选择“使用站点分段规则”时，如果对应站点未设置分段规则，则使用建筑的分段规则。当数据类型选择“日”时；统一使用建筑分段规则。</span> -->
                    <span>{{$t('Kanban.a24')}}</span> 
                  </a-descriptions-item>
              </a-descriptions>
            </div>
            <table>
              <tr class="sec-box-head-row">
                  <!-- 月份 -->
                  <th class="sec-box-head-row-th sec-box-row-title" >{{$t('energy.enedata442')}}</th>
                  <th style="padding:4px 0;"      v-for="i in 12" :key="i">{{item.months[i-1]}}</th>
                  <th class="sec-box-head-row-th sec-box-row-title"></th>
              </tr>
               <tr class="sec-box-head-row">
                  <!-- 小时 -->
                  <th class="sec-box-head-row-th sec-box-row-title" >{{$t('energy.enedata157')}}</th>
                  <th style="padding:4px 0;"      v-for="i in 12" :key="i">
                      <a-select  class="sec-box-row-len" :placeholder="placeholder" :title="$t('Kanban.a25')"
                        @change="(value, option) => batchEnergySectionChange(value, option,item,i-1,'col')"  >
                      <a-select-option :title="ritem.name"  v-for="(ritem, index) in item.types" :key="index"  :value="ritem.no"  >
                          {{ ritem.text }}
                      </a-select-option>
                    </a-select>
                  </th>
                  <!-- 快捷选择 -->
                  <th class="sec-box-head-row-th sec-box-row-title"> <span>{{$t('Kanban.a27')}}</span> </th>
              </tr>
              <tr :class="('sec-box-row-'+(i%2))"  v-for="(shour,i) in item.hours" :key="i">
                <th class="sec-box-row-title">{{shour}}</th>
                <td v-for="j in 12" :key="j"  >
                  <a-select v-model="item.energySectionRules[j-1][i].value" class="sec-box-row-len" :placeholder="placeholder" 
                        @change="(value, option) => energySectionChange(value, option,item,j-1,i)"
                      
                      >
                      <a-select-option :title="ritem.name"
                          v-for="(ritem, index) in item.types"
                          :key="index"
                          :value="ritem.no" 
                          >
                          {{ ritem.text }}
                      </a-select-option>
                    </a-select>
                </td>
                <th>
                    <a-select  class="sec-box-row-len" :placeholder="placeholder" :title="$t('Kanban.a26')"
                        @change="(value, option) => batchEnergySectionChange(value, option,item,i,'row')"  >
                      <a-select-option :title="ritem.name"  v-for="(ritem, index) in item.types" :key="index"  :value="ritem.no"  >
                          {{ ritem.text }}
                      </a-select-option>
                    </a-select>
                  </th>
              </tr>
            </table>
          
        </div>

        <!-- 分公司 -->
        <div v-if="item.ruleModual==10" v-loading="loading" :element-loading-text="$t('alarm.a0')" style="width:100%;height:100%; position: relative;">
          <a-empty v-if="item.branchList.length==0"  />
          <div v-else v-for="(branch,index) in item.branchList" :key="index" class="branch-box">
              <a-row style="border-bottom:1px solid #e8e8e8;">
               
                <a-col :span="12" style="padding:5px;">
                     <!-- :style="{color: branch.color}" -->
                     <h3> {{branch.name}}</h3>
                </a-col>
                <a-col :span="6" style="padding:5px;">
                    <div class="cls" :style="{backgroundColor: branch.color}">
                        {{branch.color}}
                    </div>
                </a-col>
                <a-col :span="4"  style="padding:5px;"  >
                    <a-descriptions title="" size="small">
                      <a-descriptions-item :label="$t('Kanban.a13')" :span="3">
                        <!-- :style="{backgroundColor: (branch.display?branch.color:'#e8e8e8')}" -->
                        <a-switch v-model="branch.display" :disabled="true"/>
                      </a-descriptions-item>
                    </a-descriptions>
                </a-col>
                 <a-col :span="2" style="padding:5px;text-align: right;" >
                   <!-- title="编辑信息" -->
                    <a-button type="primary" shape="circle" icon="edit"  @click="updateBranch(item,branch)" :title="$t('Kanban.a40')" />
                    <!-- title="删除信息" -->
                    <a-button type="danger"  shape="circle" icon="delete" style="margin-left:5px;"   @click="deleteBranch(item,branch)" :title="$t('Kanban.a54')" />
                 </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="height:100%;padding:5px;">
                  <div class="space-between" style="padding:5px 0;width:100%;">
                      <div style="width:50%;font-size:16px;">
                        <!-- 能源使用量 -->
                        {{$t('Kanban.a41')}}
                      </div>
                      <div style="width:50%;">
                           
                      </div>
                  </div>
                  <div style="width:100%;height:240px;border:1px solid #e8e8e8;padding:5px;">
                    <a-table :columns="columns2" :data-source="branch.energyPointList" :scroll="Yscroll" style="width:100%;height:100%;" :pagination="false" size="middle">
                      <!--  -->
                        <a-select slot="tags" slot-scope="tags,record" v-model="record.value" :placeholder="placeholder" style="width:120px;" :disabled="true"> 
                          <a-select-option :title="ritem.name"  v-for="(ritem, ikey) in tags" :key="ikey"  :value="ritem.no"  >
                              {{ ritem.name }}
                          </a-select-option>
                        </a-select>
                      </a-table>
                  </div>
                </a-col>
                <a-col :span="12"  style="height:100%;padding:5px;">
                    <div class="space-between" style="padding:5px 0;width:100%;">
                      <div style="width:50%;font-size:16px;">
                        <!-- 清洁能源发电量 -->
                        {{$t('Kanban.a42')}}
                      </div>
                      <div style="width:50%;">
                           
                      </div>
                    </div>
                    <div style="width:100%;height:240px;border:1px solid #e8e8e8;padding:5px;">
                        <a-table :columns="cleanColumns2" :data-source="branch.cleanPointList" :scroll="Yscroll" :pagination="false" size="middle">
                        </a-table>
                  </div>
                </a-col>
              </a-row>
          </div>
        </div>
      </div>
    </a-card>
    <!-- title="添加公司信息" -->
    <a-modal v-model="visible" :title="$t('Kanban.a39')" @ok="saveBranch" :width="1000">
         <div v-loading="frmloading" :element-loading-text="$t('alarm.a0')">
              <a-form-model ref="branchForm" 
                  :model="branch" 
                  :rules="rules"
                  :label-col="formItemLayout.labelCol" 
                  :wrapper-col="formItemLayout.wrapperCol"  
                  >
                  <a-row >
                    <a-col :span="12">
                        <a-form-model-item  ref="name" prop="name">
                              <span slot="label">
                                {{$t('Kanban.a43')}}
                                &nbsp;
                                <a-tooltip :title='tooltip'>
                                <a-icon type="question-circle-o" />
                                </a-tooltip>
                            </span>
                            <a-input 
                                v-model="branch.name"
                                @blur="
                                () => {
                                    $refs.name.onFieldBlur();
                                }
                                "
                                :placeholder="inputLabel"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-descriptions title="" size="small">
                            <!-- label="显示颜色" -->
                            <a-descriptions-item :label="$t('Kanban.a44')" :span="3">
                                  <div style="display:flex;">
                                      <a-input type="color" v-model="branch.color" style="width: 50px;padding:4px;cursor: pointer;" :title="$t('energy.enedata431')"></a-input>
                                      <div class="cls" :style="{backgroundColor: branch.color}">
                                          {{branch.color}}
                                      </div>
                                  </div>
                            </a-descriptions-item>
                        </a-descriptions>
                    </a-col>
                    <a-col :span="4"  style="padding:5px;"  >
                        <a-descriptions title="" size="small">
                          <a-descriptions-item :label="$t('Kanban.a13')" :span="3">
                            <a-switch v-model="branch.display" :style="{backgroundColor: (branch.display?branch.color:'#e8e8e8')}" />
                          </a-descriptions-item>
                        </a-descriptions>
                    </a-col>
                    
                  </a-row>
                  <a-row>
                      <a-col :span="12" style="height:100%;padding:5px;">
                        <div class="space-between" style="padding:5px 0;width:100%;">
                             <div style="width:50%;font-size:16px;">
                              <!-- 能源使用量 -->
                              {{$t('Kanban.a41')}}
                            </div>
                            <div style="width:50%;">
                                  <a-cascader
                                    style="width: 100%;min-width:160px;"
                                    :field-names="{ label: 'name', value: 'key', children: 'children' }"
                                    :popup-style="{ maxWidth: '1366px',maxHeight: '400px', overflow: 'auto' }"
                                    :options="branch.dataList"
                                    :placeholder="placeholder"
                                    @change="(value, selectedOptions) => addPoint(value, selectedOptions,branch)"
                                    :title="$t('Kanban.a45') "
                                  />
                              </div>
                        </div>
                        <div style="width:100%;height:240px;border:1px solid #e8e8e8;padding:5px;">
                          <a-table :columns="columns" :data-source="branch.energyPointList" :scroll="Yscroll" style="width:100%;height:100%;" :pagination="false" size="middle">
                              <a slot="name" slot-scope="text">{{ text }}</a>
                              <a-select slot="tags" slot-scope="tags,record" v-model="record.value" :placeholder="placeholder" style="width:120px;" 
                                    :title="$t('Kanban.a55') ">
                                <a-select-option :title="ritem.name"  v-for="(ritem, index) in tags" :key="index"  :value="ritem.no"  >
                                    {{ ritem.name }}
                                </a-select-option>
                              </a-select>

                              <span slot="action" slot-scope="record">
                                <a-button type="primary" shape="circle" icon="delete" size="small" @click="deletePoint(record,branch)"  :title="$t('Kanban.a46') " />
                              </span>
                            </a-table>
                        </div>
                      </a-col>
                      <a-col :span="12"  style="height:100%;padding:5px;">
                          <div class="space-between" style="padding:5px 0;width:100%;">
                            <div style="width:50%;font-size:16px;">
                              <!-- 清洁能源发电量 -->
                              {{$t('Kanban.a42')}}
                            </div>
                            <div style="width:50%;">
                                  <a-cascader
                                    style="width: 100%;min-width:160px;"
                                    :field-names="{ label: 'name', value: 'key', children: 'children' }"
                                    :popup-style="{ maxWidth: '1366px',maxHeight: '400px', overflow: 'auto' }"
                                    :options="branch.dataList"
                                    :placeholder="placeholder"
                                    @change="(value, selectedOptions) => addCleanPoint(value, selectedOptions,branch)"
                                    :title="$t('Kanban.a45') "
                                  />
                              </div>
                          </div>
                          <div style="width:100%;height:240px;border:1px solid #e8e8e8;padding:5px;">
                              <a-table :columns="cleanColumns" :data-source="branch.cleanPointList" :scroll="Yscroll" :pagination="false" size="middle">
                                  <a slot="name" slot-scope="text">{{ text }}</a>
                                  <span slot="action" slot-scope="record">
                                    <a-button type="primary" shape="circle" icon="delete" size="small" @click="deleteCleanPoint(record,branch)" :title="$t('Kanban.a46') "/>
                                  </span>
                              </a-table>
                        </div>
                      </a-col>
                    </a-row>
                  
              </a-form-model>
          </div>

        
    </a-modal>
  </div>
</template>

<script>

import { saveEnergyKanBanBranch,deleteEnergyKanBanBranch } from "../../../api/energy";
const colors = ['#ed6f72','#fbdd9f','#94cb7a','#5673c3','#fb8e91','#feebc3','#b4e79c','#7590e1','#fdc9cb','#7682ce','#a0a9ec','#c3caf8','#e8eaff'];


const formItemLayout = {
  labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
  },
};

export default {
  name: "clientKanBanSetting",
  props: {
    list: {
      type: Array,
      default:[],
    },
  },
  data() {
    let name = this.$t("Kanban.a43");//'公司名称';
    let spLabel = this.$t("energy.setting.reportGr.spLabel").replace('${label}',name);
    let lenLabel = this.$t("energy.setting.reportGr.lenLabel").replace('${label}',name).replace('${min}',1).replace('${max}',30);
    let inputLabel = this.$t("energy.setting.reportGr.inputLabel");
   
    inputLabel = inputLabel.replace('${label}',name);
    let tooltip =this.$t("energy.setting.reportGr.stop")+' [ ` ~ ! @ # $ % ^ & *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';

    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
        if(regEn.test(value)) {
            callback(new Error(spLabel));
        } else {
            callback();
        }
    };
    return {
      loading: false, 
      placeholder:this.$t('energy.placeSelect'),
      kblist:[],
      firstRow:{paddingTop:'5px'},
      columns:[
        {
          title: this.$t('energy.setting.reportGr.pointName'),//'信号名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: this.$t('Kanban.a36'),// 'KPI规则',
          key: 'tags',
          dataIndex: 'tags',
          scopedSlots: { customRender: 'tags' },
          width:130,
        },
        {
          title: this.$t('edge.edge024'),//'操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width:100,
        },
      ],
      cleanColumns:[
        {
          title: this.$t('energy.setting.reportGr.pointName'),//'信号名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title:  this.$t('edge.edge024'),//'操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width:100,
        },
      ],
      columns2:[
        {
          title: this.$t('energy.setting.reportGr.pointName'),//'信号名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: this.$t('Kanban.a36'),//'KPI规则',
          key: 'tags',
          dataIndex: 'tags',
          scopedSlots: { customRender: 'tags' },
          width:130,
        }
      ],
      cleanColumns2:[
        {
          title: this.$t('energy.setting.reportGr.pointName'),//'信号名称',
          dataIndex: 'name',
          key: 'name'
        }
      ],
      siteYearRuleMap:new Map(),
      cidx:0,
      Yscroll:{ y: 200 },
      visible:false,
      branch: {
        id:0,
        branchId:0,
        name:'',
        color:'',
        display:true,
        energyPointList:[],
        cleanPointList:[],
        dataList:[],
      },
      rules: {
          name: [
              { required: true, message: inputLabel, trigger: 'blur' },
              { min: 1, max: 30, message: lenLabel, trigger: 'blur' },
              { validator:normalName, trigger: 'blur' },
          ]
      },
      frmloading:false,
      formItemLayout,
      tooltip:tooltip,
      inputLabel:inputLabel,
      spLabel:spLabel,
      lenLabel:lenLabel,
    };
  },
  created(){
    console.log('created Result',this.list.length);
  },
  mounted(){
    console.log('mounted Result',this.list.length);  
    this.siteYearRuleMap = new Map();
    this.list.forEach(ele => {
        if(ele.ruleModual===10){
            ele.yearRuleData.forEach(element => {
              this.siteYearRuleMap.set(element.no+"",element.children);
            });
            ele.branchList.forEach(branch => {
              if(branch.energyPointList){
                  branch.energyPointList.forEach(element => {
                      element.tags = this.siteYearRuleMap.get(element.siteId+"");
                      if(element.value && !isNaN(parseInt(element.value+""))){
                        element.value = parseInt(element.value+"");
                      }else{
                        element.value = undefined;
                      }
                  });
                }
            });
            this.cidx = ele.branchList.length;
        }          
  });
  },
  methods: {
    updateBranch(item,branch){
        // let siteYearRuleMap = new Map();
        // item.yearRuleData.forEach(element => {
        //   siteYearRuleMap.set(element.no+"",element.children);
        // });
        // branch.siteYearRuleMap=siteYearRuleMap;
        // if(branch.energyPointList){
        //       branch.energyPointList.forEach(element => {
        //             element.tags = siteYearRuleMap.get(element.siteId+"");
        //             element.value = parseInt(element.value+"");
        //     });
        // }
        this.frmloading = false;
        branch.dataList=item.dataList;
        let data = JSON.stringify(branch);
        this.resetForm();

        this.branch = JSON.parse(data);
        this.visible = true;
    },
    addBranch(item){
        this.frmloading = false;
        let siteYearRuleMap = new Map();
        item.yearRuleData.forEach(element => {
            siteYearRuleMap.set(element.no+"",element.children);
        });
        
        let bnch = {
          id:0,
          branchId:item.id,
          name:'',
          color:colors.length>this.cidx?colors[this.cidx++]:colors[0],
          display:true,
          energyPointList:[],
          cleanPointList:[],
          dataList:item.dataList,
          siteYearRuleMap:siteYearRuleMap,
        }
        this.resetForm();
        this.branch = bnch;
        this.visible = true;
    },
    saveBranch() {
      this.$refs.branchForm.validate(valid => {
            if (valid) {
                  this.$confirm({
                    title: this.$t('Kanban.a47'),//'确认要保存所编辑的信息吗？',
                    centered: true,
                    onOk: () => {
                        this.frmloading = true;
                        let param  = this.branch;
                        saveEnergyKanBanBranch(param)
                        .then((res) => {
                            console.log('SaveEnergyKanBanBranch Result',res);
                            if(res.errorCode ==='00' || res.errorCode ==='03'){
                                this.$message.success(res.msg);
                                this.visible = false;
                                this.search();
                                
                            }else{
                                this.$message.error(res.msg);
                                this.frmloading = false;
                            }
                        })
                        .catch((err) => {
                            this.frmloading = false;
                            console.log(err);
                        });
                    },
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    },
    search(){
      this.$parent.search();
    },
    resetForm() {
        if(this.$refs.branchForm){
            this.$refs.branchForm.resetFields();
        }
    },
    deleteBranch(item,branch){
        this.$confirm({
              title: this.$t('Kanban.a48'),//'确认要删除吗？',
              centered: true,
              onOk: () => {
                  this.loading = true;
                  deleteEnergyKanBanBranch(branch.id)
                  .then((res) => {
                      console.log('deleteEnergyKanBanBranch Result',res);
                      if(res.errorCode ==='00' || res.errorCode ==='05'){
                          this.$message.success(res.msg);
                          this.search();
                      }else{
                          this.$message.error(res.msg);
                      }
                      this.loading = false;
                  })
                  .catch((err) => {
                      this.loading = false;
                      console.log(err);
                  });
              }
        });
        
    },
    addCleanPoint(value,options,parent){
        let len = options.length;
        let point = options[len-1];
        let key = point.key;
        let result = parent.cleanPointList.filter((item) => {
            return  item.key===key;
        });
        if(result.length>0){
            // this.$message.error('所选择信号点已存在，请不要重复选择。');
            this.$message.error(this.$t('Kanban.a49'));//
            return;  
        }
        let names = [];
        for (let i = 0; i < 3; i++) {
            names.push(options[i].name);
        }
        names.push(point.name);
        let name = names.join(' / ');
        let item =  {
            key: key,
            name: name
        };
        parent.cleanPointList.push(item);

    },
    deleteCleanPoint(record,parent){
      this.$confirm({
          title: this.$t('Kanban.a48'),//'确认要删除吗？',
          centered: true,
          onOk: () => {
              let arr = [];
              parent.cleanPointList.forEach(element => {
                if(element.key!=record.key){
                    arr.push(element);
                }
              });
              parent.cleanPointList = arr;
          }
      });
       
    },
    addPoint(value,options,parent){
        let len = options.length;
        let point = options[len-1];
        let key = point.key;
        let result = parent.energyPointList.filter((item) => {
            return  item.key===key;
        });
        if(result.length>0){
            this.$message.error('所选择信号点已存在，请不要重复选择。');
            return;  
        }
        let names = [];
        for (let i = 0; i < 3; i++) {
            names.push(options[i].name);
        }
        names.push(point.name);
        let name = names.join(' / ');
        let item =  {
            key: key,
            name: name,
            value: undefined,
            siteId:point.siteId,
            tags:  this.siteYearRuleMap.get(point.siteId+""),
        };
        parent.energyPointList.push(item);
    },
    deletePoint(record,parent){
      this.$confirm({
          title: this.$t('Kanban.a48'),//'确认要删除吗？',
          centered: true,
          onOk: () => {
            let arr = [];
            parent.energyPointList.forEach(element => {
              if(element.key!=record.key){
                  arr.push(element);
              }
            });
            parent.energyPointList = arr;
          }
      });
    },
    batchEnergySectionChange(value, option,item,i,type){
        if(type=='row'){
          for (let k = 0; k < 12; k++) {
            const element = item.energySectionRules[k][i];
            element.value = value;
          }
        }else{
           for (let k = 0; k < 24; k++) {
            const element = item.energySectionRules[i][k];
            element.value = value;
          }
        }
    },
    energySectionChange(value, option,item,i,j){
       
    },
    addrChange(value, selectedOptions,record){
        let names = [];
        selectedOptions.forEach(element => {
          names.push(element.name);
        });
        record.addrNames = names;
    },
    
  },
  computed: {
   
  }
};
</script>

<style scoped>
#clientKanBanSetting {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    font-family: "Microsoft YaHei";
}
.cls{
  width: 120px;color: rgb(255, 255, 255);text-align: center;padding: 5px;
}
.card{
  width: 100%;
  height: 320px;
  margin: 5px;
}
.card-10{
  width: 100%;
  margin: 5px;
  min-height: 320px;
}
.card-13{
  width: 100%;
  margin: 5px;
  margin-bottom: 0.3rem;
}
.space-between{
  display: flex;
  justify-content: space-between;
}
.sec-box{
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.sec-box-head-row{
  border-bottom:1px solid #f5f5f5;
  background-color:#e8e8e8;
}
.sec-box-head-row-th{
  width: 80px;
  padding:4px 0;
}
.sec-box-row-0{
  background-color: aliceblue;
}
.sec-box-row-1{
  background-color:#ffffff;
}
.sec-box-row-title{
  width: 80px;
  white-space:nowrap;
  padding-top:4px;
  background-color:#e8e8e8;
  padding-left:5px;
}
.sec-box table{
  width: 100%;
  margin-bottom:2px ;
}
.sec-box table tr.sec-box-row-0:hover{
  background-color:#7682ce;
}
.sec-box table tr.sec-box-row-1:hover{
  background-color:#7682ce;
}
.sec-box-row-len{
  width: 80px;
}

div /deep/.ant-card-head-title{
  padding:8px 0;
}
div /deep/.ant-card-body {
    padding: 4px 8px ;
    zoom: 1;
    height: calc(100% - 50px);
}
div /deep/.ant-switch-checked {
    background-color: #7682ce;
}
div /deep/.ant-card-head {
    background: #e8e8e8;
}

div /deep/.ant-select-tree-node-selected{
     background-color: #7682ce;
}
div /deep/.ant-descriptions-item-label{
  white-space: nowrap;
} 
div /deep/.ant-form-item-label{
  text-align: left;
}
.branch-box{
  box-shadow: 0 0px 10px #bdbcbc;
  padding:5px;
  margin:5px;
}
.err-cls{
  border:1px solid #f44336
}
div /deep/.ant-modal-body{
  padding:8px;
}
</style>